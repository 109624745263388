import axios from 'axios'
const api = "pedidos/tablero"

const tablero = {
    getData: () => axios.get(`${api}/index`),
    buscarPedido: (id) => axios.get(`${api}/pedido/${id}`),
    porCedis: (id) => axios.get(`${api}/por-cedis`),
    porCedisFecha: (params) => axios.get(`${api}/por-cedis-fecha`, { params }),
    updateFranja: (data) => axios.post(`${api}/update-franja`, data),
    buscarDeliveries: (params) => axios.get(`${api}/buscar-delivery`, { params }),
    buscarLeecheros: (params) => axios.get(`${api}/buscar-leecheros`, { params }),
    showPedido: (id) => axios.get(`${api}/show-pedido/${id}`),
    showPedidos: params => axios.get(`${api}/show-pedidos`, {params}),
    asignarMultiple: (data) => axios.post(`${api}/asignar-multiple`, data),
}
export default tablero
